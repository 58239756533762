export const LOGOUT = "logout";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_WALLET = "setWallet";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  currentWallet: null,
};

const getters = {
  currentWallet(state) {
    return state.currentWallet;
  },
};

const actions = {
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_WALLET](state, wallet) {
    state.currentWallet = wallet;
  },
  [PURGE_AUTH](state) {
    state.currentWallet = null;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
