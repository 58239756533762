// mutation types
export const SET_LISTED = "setListed";
export const SET_PROMOTED = "setPromoted";
export const SET_TRENDY = "setTrendy";
export const SET_LISTED_ITEM = "setListedITem";

export default {
  state: {
    listed: [],
    promoted: [],
    trendy: [],
  },
  getters: {
    listed(state) {
      return state.listed;
    },
    promoted(state) {
      return state.promoted;
    },
    trendy(state) {
      return state.trendy;
    },
  },
  actions: {
    [SET_LISTED](state, payload) {
      state.commit(SET_LISTED, payload);
    },
    [SET_PROMOTED](state, payload) {
      state.commit(SET_PROMOTED, payload);
    },
    [SET_TRENDY](state, payload) {
      state.commit(SET_TRENDY, payload);
    },
    [SET_LISTED_ITEM](state, payload) {
      state.commit(SET_LISTED_ITEM, payload);
    },
  },
  mutations: {
    [SET_LISTED](state, payload) {
      state.listed = payload;
    },
    [SET_PROMOTED](state, payload) {
      state.promoted = payload;
    },
    [SET_TRENDY](state, payload) {
      state.trendy = payload;
    },
    [SET_LISTED_ITEM](state, updatedItem) {
      state.listed = [
        ...state.listed.map(item =>
          item.id !== updatedItem.id ? item : { ...item, ...updatedItem }
        ),
      ];
    },
  },
};
