import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;

// Vue 3rd party plugins
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";

import "@/core/plugins/inline-svg";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import './registerServiceWorker'

Sentry.init({
  Vue,
  dsn:
    "https://316e8358105745fe8173fa11375f22ca@o290161.ingest.sentry.io/5780253",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

// API service init
ApiService.init();

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount("#app");
